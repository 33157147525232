@import "reset.css";

body {
	animation: bg_anime 10s ease infinite;
	background: center / cover fixed;
	color: rgba(0, 0, 0, 0.87);
	font-family: Noto Sans JP;
}
body::-webkit-scrollbar {
	display: none;
}
@keyframes bg_anime {
	0% {
		background-image: url(../public/images/bg_main_01_sp.png);
	}
	40% {
		background-image: url(../public/images/bg_main_01_sp.png);
	}
	50% {
		background-image: url(../public/images/bg_main_02_sp.png);
	}
	90% {
		background-image: url(../public/images/bg_main_02_sp.png);
	}
	100% {
		background-image: url(../public/images/bg_main_01_sp.png);
	}
}
@media (min-width: 960px) {
	@keyframes bg_anime {
		0% {
			background-image: url(../public/images/bg_main_01_pc.png);
		}
		40% {
			background-image: url(../public/images/bg_main_01_pc.png);
		}
		50% {
			background-image: url(../public/images/bg_main_02_pc.png);
		}
		90% {
			background-image: url(../public/images/bg_main_02_pc.png);
		}
		100% {
			background-image: url(../public/images/bg_main_01_pc.png);
		}
	}
}
.contents {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

/* ---------------------------------------------
* 追従ヘッダー & フッター（PC表示時）
-----------------------------------------------*/
.fixed-contents-inner {
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	justify-content: space-between;
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	pointer-events: none;
}
.fixed-contents-inner.isFixed {
	position: fixed;
}
.fixed-contents-left {
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc((100vw - 375px) / 2);
}
.fixed-contents-left > .primary-txt {
	color: rgba(0, 0, 0, 0.87);
	font-size: 32px;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	pointer-events: auto;
}
.fixed-contents-right {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width: calc((100vw - 375px) / 2);
	padding: 0 20px 20px 0;
	box-sizing: border-box;
}
@media (min-width: 960px) {
	.fixed-contents-inner {
		display: flex;
	}
	.fixed-contents-left > .primary-txt:hover {
		opacity: 0.5;
	}
}

/* ---------------------------------------------
* ヘッダー
-----------------------------------------------*/
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	padding: 20px 16px;
	box-sizing: border-box;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	animation-name: fadeInAnime;
}
.header.isScrolled {
	animation-name: fadeOutAnime;
	pointer-events: none;
}
.header-link {
	text-align: center;
}
.header-back-btn {
	position: absolute;
	top: 0;
	left: 0;
	width: 56px;
	height: 56px;
	padding: 0;
	border: none;
	background: url(../public/images/icon_arrow_back.svg) center / 24px no-repeat transparent;
}
.header-link > .primary-txt {
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
}
@media (min-width: 960px) {
	.header {
		display: none;
	}
}
@keyframes fadeInAnime {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeOutAnime {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
/* ---------------------------------------------
* common
-----------------------------------------------*/
.primary-txt {
	font-family: 'Jost', sans-serif;
	font-weight: 700;
	letter-spacing: 3.2px;
}
.common-btn {
	width: 100%;
	padding: 16px;
	background-color: rgba(255, 255, 255, 0.40);
	border: none;
	border-radius: 4px;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
	color: #000000DE;
	font-size: 14px;
	font-weight: 700;
	font-family: Noto Sans JP;
	text-align: left;
	cursor: pointer;
}
.common-btn + .common-btn {
	margin-top: 8px;
}

/* ---------------------------------------------
* KV
-----------------------------------------------*/
.kv-section {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.kv-section .primary-txt {
	font-size: 32px;
	line-height: 1.5;
	text-align: center;
}
.scroll-icon {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	font-size: 10px;
	font-family: 'Jost', sans-serif;
	line-height: 1;
	letter-spacing: 4px;
}
.scroll-icon::after {
	display: block;
	content: "";
	width: 1px;
	height: 24px;
	margin: 8px auto 0;
	background-color: rgba(0, 0, 0, 0.87);
	font-family: 'Jost', sans-serif;
}

/* ---------------------------------------------
* TOP メッセージ
-----------------------------------------------*/
.message-section {
	position: relative;
}
.message-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #FFFFFF;
	text-align: center;
}
.message-heading-txt {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.7;
}
.message-txt {
	margin-top: 32px;
	font-size: 14px;
	font-weight: 400;
	line-height: 2.6;
}
@media (min-width: 960px) {
	.message-wrap {
		height: 812px;
		max-width: 375px;
		margin: 0 auto;
	}
}

/* ---------------------------------------------
* TOP タレント一覧
-----------------------------------------------*/
.talent-list {
	counter-reset: num 0; 
}
.talent-list > li {
	position: relative;
	height: calc(100vh + 64px);
	padding-top: 64px;
	box-sizing: border-box;
	background: bottom 115px center / calc(800 / 812 * 100vh) no-repeat;
}
.talent-list > li:first-child {
	padding-top: 80px;
	height: calc(100vh + 80px);
}
.talent-list > li.takano {
	background-position: bottom 115px right 75%;
}
.talent-list > li.coming-soon {
	background-image: url(../public/images/img_coming_soon.png);
}
.talent-list > li::before,
.talent-list > li::after {
	content: "";
	position: absolute;
	width: 100%;
	background-color: #FFFFFF;
}
.talent-list > li::before {
	bottom: 114px;
	height: 120px;
	clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
.talent-list > li::after {
	bottom: 0;
	height: 115px;
	z-index: -1;
}
.talent-link {
	display: block;
	height: 100%;
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
}
.talent-list-num {
	position: absolute;
	left: 16px;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke: 2px #FFFFFF;
	font-size: 78px;
	line-height: 1;
}
.talent-list-num::after {
	counter-increment: num 1;
	content: "0"counter(num)".";
	font-family: Gabarito;
}
.talent-list > li:nth-of-type(n + 10) .talent-list-num::after {
	content: counter(num)".";
}
.talent-info {
	position: absolute;
	z-index: 1;
	bottom: 40px;
	width: 100%;
	box-sizing: border-box;
	padding-right: 16px;
	text-align: right;
}
.coming-soon .talent-info {
	bottom: 92px;
}
.talent-info .primary-txt {
	font-size: 32px;
	line-height: 1.2;
}
.talent-txt {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 8px;
	font-size: 12px;
	font-weight: 500;
}
.talent-txt::after {
	content: "";
	width: 16px;
	height: 1px;
	margin-left: 8px;
	background-color: rgba(0, 0, 0, 0.87);
}
.talent-arrow {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 8px;
	font-family: 'Jost', sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 2.4px;
}
.talent-arrow::after {
	content: "";
	width: 24px;
	height: 24px;
	margin-left: 4px;
	background: url(../public/images/icon_link_arrow.svg) no-repeat;
}
@media (min-width: 768px) and (max-width: 959px) {
	.talent-info {
		padding-right: 40px;
	}
}
@media (min-width: 960px) {
	.talent-section {
		min-width: 375px;
		margin: 0 auto;
	}
	.talent-info {
		padding-right: 16px;
	}
	.talent-list > li {
		height: 876px;
		background-size: 800px;
	}
	.talent-list > li:first-child {
		height: 892px;
	}
	.talent-link:hover {
		opacity: 0.5;
	}
}

/* ---------------------------------------------
* 話者ページ
-----------------------------------------------*/
.talent-page-top-wrap {
	position: relative;
	display: flex;
	align-items: flex-end;
	height: 100vh;
	box-sizing: border-box;
	background: bottom 0 center / calc(800 / 812 * 100vh) no-repeat;
}
.talent-page-top-wrap.takano {
	background-position: bottom 0 right 75%;
}
.talent-media-info-wrap {
	position: absolute;
	flex: 1;
	width: 100%;
	padding: 64px 16px 58px;
	box-sizing: border-box;
}
.talent-media-info-filter {
	position: absolute;
	width: 100%;
	height: 246px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 25%, rgba(255, 255, 255, 0.75) 100%);
	backdrop-filter: blur(1px);
}
.talent-media-info-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.talent-media-info-inner > .primary-txt {
	font-size: 32px;
	line-height: 1.2;
}
.talent-about-txt {
	padding: 24px 16px;
	font-size: 14px;
	line-height: 1.6;
}
.talent-episode-section {
	padding: 0 16px 24px;
	box-sizing: border-box;
}
.section-title {
	font-size: 16px;
	font-weight: 700;
}
.talent-episode-list {
	margin-top: 16px;
}
.talent-episode-list > li {
	position: relative;
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.50);
	background-color: rgba(255, 255, 255, 0.40);
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
	backdrop-filter: blur(4px);
}
.talent-episode-list > li + li {
	margin-top: 16px;
}
.talent-episode-list > li > .talent-platform-list {
	position: absolute;
	bottom: 20px;
	right: 20px;
	margin-top: 0;
}
.talent-episode-cassette {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	color: rgba(0, 0, 0, 0.60);
	text-decoration: none;
}
.talent-episode-cassette-img {
	flex-shrink: 0;
	width: 64px;
	height: 64px;
}
.talent-episode-cassette-img > img {
	width: 100%;
}
.talent-episode-cassette-wrap {
	flex: 1;
	margin-left: 12px;
}
.talent-episode-cassette-head {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}
.talent-episode-cassette-title {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-top: 4px;
	color: rgba(0, 0, 0, 0.87);
	font-size: 14px;
	font-weight: 700;
	line-height: 1.6;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
}
.talent-episode-cassette-text {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-top: 8px;
	font-size: 12px;
	line-height: 1.5;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
}
.talent-episode-cassette-time {
	display: flex;
	align-items: center;
	margin: 12px 0 4px;
	font-size: 12px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	line-height: 1;
}
.talent-episode-cassette-time::before {
	content: "";
	width: 16px;
	height: 16px;
	margin-right: 4px;
	background: url(../public/images/icon_play.svg) center / contain;
}
.talent-platform-list {
	display: flex;
	margin-top: 24px;
}
.talent-platform-list > li {
	width: 24px;
	height: 24px;
}
.talent-platform-list > li + li {
	margin-left: 16px;
}
.talent-platform-link {
	display: block;
}
.talent-platform-link > img {
	width: 100%;
}
@media (min-width: 768px) and (max-width: 959px) {
	.talent-media-info-wrap {
		padding: 64px 40px 58px;
	}
	.talent-about-txt {
		padding: 24px 40px;
	}
	.talent-episode-section {
		padding: 0 40px 24px;
	}
}
@media (min-width: 960px) {
	.talent-about-txt-section,
	.talent-episode-section,
	.talent-page-top-section {
		width: 375px;
		max-width: 375px;
		margin: 0 auto;
	}
	.talent-page-top-wrap {
		height: 812px;
		background-size: 800px;
	}
	.talent-media-info-link:hover {
		opacity: 0.5;
	}
	.talent-episode-cassette:hover {
		opacity: 0.5;
	}
	.talent-platform-link:hover {
		opacity: 0.5;
	}
}

/* ---------------------------------------------
* 再生画面
-----------------------------------------------*/
.talent-voice-media-img-section {
	display: flex;
	justify-content: center;
	padding: 80px 0 45px;
}
.talent-voice-media-img-wrap {
	width: 200px;
	height: 200px;
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 88px 35px 0px rgba(0, 0, 0, 0.01), 0px 138px 38px 0px rgba(0, 0, 0, 0.00);
}
.talent-voice-media-img {
	width: 100%;
	height: 100%;
	background: center / cover no-repeat;
}
.talent-voice-info-wrap {
	padding: 24px 16px;
}
.talent-voice-info-title {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.5;
}
.talent-voice-info-name {
	margin-top: 4px;
	color: rgba(0, 0, 0, 0.87);
	font-size: 14px;
	font-family: 'Jost', sans-serif;
	font-weight: 400;
	line-height: 1.2;
	text-decoration: none;
}
.talent-voice-info-name:hover {
	opacity: 0.5;
}
.talent-voice-info-detail-wrap {
	display: flex;
	margin-top: 4px;
	color: rgba(0, 0, 0, 0.60);
	font-size: 12px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	line-height: 1.2;
}
.talent-voice-audio-wrap {
	text-align: center;
}
.talent-voice-audio {
	margin-top: 24px;
	width: 100%;
	max-width: 343px;
	height: 32px;
}
.talent-voice-info-text-wrap {
	margin-top: 24px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
}
.talent-voice-info-text-check {
	display: none;
}
.talent-voice-info-text {
	overflow: hidden;
	transition: height 0.3s linear;
}
.talent-voice-info-text-check:checked ~ .talent-voice-info-text {
	height: auto;
}
.talent-voice-info-text-wrap.isClosed > .talent-voice-info-text-more-btn-wrap {
	display: block;
}
.talent-voice-info-text-check:checked ~ .talent-voice-info-text-more-btn-wrap {
	display: none;
}
.talent-voice-info-text-more-btn-wrap {
	display: none;
	margin-top: 4px;
	text-align: right;
}
.talent-voice-info-text-more-btn {
	appearance: none;
	padding: 0;
	background: none;
	border: none;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.6;
	cursor: pointer;
}
.talent-message-btn-section {
	margin-bottom: 24px;
	padding: 0 16px;
	box-sizing: border-box;
}
.talent-voice-message-list-wrap {
	position: fixed;
	left: 50%;
	bottom: 0;
	z-index: 1;
	width: 100%;
	max-height: 80%;
	padding: 48px 16px 22px;
	background-color: #FFFFFF;
	border-radius: 8px 8px 0px 0px;
	box-shadow: 0px -8px 24px 0px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	transform: translateY(100%);
}
.talent-voice-message-list-wrap.isOpened {
	display: block !important;
	animation: slideinBottom 0.3s ease-in-out forwards;
}
.talent-voice-message-list-wrap.isClosed {
	animation: slideoutBottom 0.3s ease-in-out forwards;
}
.talent-voice-message-list-btn {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px 16px 8px;
	background-color: #FFFFFF;
	border: none;
	border-radius: 8px 8px 0px 0px;
	color: #000000DE;
	font-weight: 700;
	cursor: pointer;
}
.talent-voice-message-list-btn::after {
	content: "";
	width: 24px;
	height: 24px;
	background: url(../public/images/icon_menu_down.svg) center / contain no-repeat;
}
.talent-voice-message-list-inner {
	overflow-y: scroll;
}
.talent-voice-message-list-inner::-webkit-scrollbar {
	display: none;
}
.talent-voice-message-list > li {
	padding: 20px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}
.talent-voice-message-list > li:first-child {
	margin-top: 1px;
}
.talent-voice-message-list > li + li {
	margin-top: 8px;
}
.talent-voice-message-list-item-wrap {
	display: flex;
	color: rgba(0, 0, 0, 0.60);
	font-family: 'Inter', sans-serif;
	font-size: 12px;
}
.talent-voice-message-list-item-time {
	display: flex;
	align-items: center;
}
.talent-voice-message-list-item-time::before {
	content: "";
	width: 16px;
	height: 16px;
	margin-right: 4px;
	background: url(../public/images/btn_new_audio.svg) center / contain no-repeat;
}
.talent-voice-message-list-item.isPlaying .talent-voice-message-list-item-time::before {
	background-image: url(../public/images/btn_new_audio_play.svg);
}
.talent-voice-message-list-item-num {
	margin-left: 16px;
}
.talent-voice-message-list-item-text {
	margin-top: 8px;
	font-size: 14px;
	line-height: 1.6;
}
@media (min-width: 768px) and (max-width: 959px) {
	.talent-voice-info-wrap {
		padding: 24px 40px;
	}
}
@media (min-width: 960px) {
	.talent-voice-media-img-section,
	.talent-voice-info-section {
		width: 100%;
		max-width: 375px;
		margin: 0 auto;
	}
	.talent-message-btn-section {
		min-width: 375px;
		margin: 0 auto 24px;
	}
	.talent-voice-message-list-wrap {
		width: 375px;
		height: 100%;
		max-height: none;
	}
}
@keyframes slideinBottom {
	0% {
		transform: translate(-50%, 100%) ;
	}
	100% {
		transform: translate(-50%, 0);
	}
}
@keyframes slideoutBottom {
	0% {
		transform: translate(-50%, 0);
	}
	100% {
		transform: translate(-50%, 100%);
	}
}


/* ---------------------------------------------
* プライバシーポリシー & 利用規約
-----------------------------------------------*/
.terms-wrap {
	padding: 96px 16px 24px;
	box-sizing: border-box;
	background-color: #FFFFFF;
}
.terms-main-heading {
	margin-bottom: 24px;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
}
.terms-main-heading:nth-of-type(2) {
	margin-top: 24px;
}
.terms-text {
	font-size: 14px;
	line-height: 160%;
}
.terms-text > em {
	font-weight: 700;
}
.terms-heading {
	margin: 32px 0 16px;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
}
.terms-sub-heading {
	margin: 16px 0 8px;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
}
.terms-num-list {
	margin-top: 16px;
	padding-left: 1em;
	list-style-type: decimal;
}
.terms-num-sub-list {
	padding-left: 1em;
	text-indent: -1em;
	list-style-type: none;
	counter-reset: cnt 0;
}
.terms-num-sub-list > li::before {
	counter-increment: cnt;
	content: "(" counter(cnt) ") ";
}
.terms-abc-list {
	padding-left: 1em;
	list-style-type: lower-alpha;
}
.terms-dots-list {
	padding-left: 1em;
	list-style-type: disc;
}
.terms-description-list {
	margin-top: 16px;	
}
.terms-description-list > div {
	display: flex;
}
.terms-table-wrap {
	margin-top: 8px;
}
.terms-num-list .terms-table-wrap {
	margin-left: -1em;
}
.terms-table-name {
	font-size: 14px;
	font-weight: 700;
}
.terms-table {
	margin-top: 12px;
	font-size: 12px;
}
.terms-table th {
	width: 110px;
	background-color: #00000014;
	line-height: 1.6;
}
.terms-table th,
.terms-table td {
	padding: 12px;
	border: 1px solid #0000001F;
	box-sizing: border-box;
}
.terms-table a {
	color: #0073E6;
}
.terms-right-text {
	margin-top: 32px;
	text-align: right;
}
@media (min-width: 768px) and (max-width: 959px) {
	.terms-text-wrap {
		padding: 0 24px;
	}
}
@media (min-width: 960px) {
	.terms-wrap {
		max-width: 375px;
		margin: 0 auto;
		padding-top: 20px;
	}
}

/* ---------------------------------------------
* フッター
-----------------------------------------------*/
.footer {
	margin-top: auto;
	padding: 32px 0 28px;
	background-color: #FFFFFF;
	text-align: center;
}
.footer .primary-txt {
	font-size: 20px;
	line-height: 1.1;
	text-align: center;
}
.footer-logo-link .primary-txt {
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
}
.footer-link-list {
	display: flex;
	justify-content: center;
	margin-top: 8px;
}
.footer-link-list > li + li {
	margin-left: 8px;
}
.footer-link {
	display: block;
	padding: 10px;
	pointer-events: auto;
}
.footer-link:hover {
	opacity: 0.5;
}
.footer-link > img {
	height: 32px;
	width: 32px;
	vertical-align: bottom;
}
.company-links {
	display: flex;
	justify-content: center;
	margin-top: 16px;
}
.fixed-contents-right .footer-link-list {
	justify-content: end;
}
.fixed-contents-right .footer-link > img {
	height: 24px;
	width: 24px;
}
.fixed-contents-right .company-links {
	justify-content: flex-end;
	margin-top: 8px;
	pointer-events: auto;
}
.company-links > li + li {
	margin-left: 16px;
}
.company-link {
	color: rgba(0, 0, 0, 0.87);
	font-size: 14px;
	line-height: 1.6;
}
.copylight {
	display: block;
	margin-top: 16px;
	font-family: 'Inter', sans-serif;
	font-size: 10px;
	font-weight: 400;
}
.fixed-contents-right .copylight {
	margin-top: 8px;
	text-align: right;
}
@media (min-width: 960px) {
	.footer {
		display: none;
	}
	.company-link:hover {
		opacity: 0.5;
	}
}